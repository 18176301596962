<template>
  <div class="container min-height flex-column justify-content-spaceBetween align-items-center">
    <div class="width-100 flex-column justify-content-start align-items-center">
      <top :active="3"></top>
      <div class="container-box copyrightRegistration flex-column justify-content-start align-items-center">
        <div class="width-100 flex-row justify-content-spaceBetween align-items-center border-b">
          <p class="fs-super-big black fw-mid">版权发布</p>
          <p class="theme-blue fs-mid">
            版权已登记的可直接搜索版权名称，添加发布<img src="../assets/image/icon.png" class="icon-img pointer" @click="addCopyright" />
          </p>

          <el-dialog :show-close="false" :visible.sync="dialogVisible" width="50%">
            <div class="search-popup flex-column justify-content-start align-items-center">
              <div class="search width-50 flex-row justify-content-center align-items-center">
                <el-input placeholder="请输入版权名称搜索" v-model="search"> </el-input>
                <img src="../assets/image/icon.png" class="icon-img" alt="" @click="copyrightClick" />
              </div>
              <div class="width-100">
                <ul class="table-title width-100 flex-row justify-content-start align-items-center">
                  <li class="width-40">版权信息</li>
                  <li class="width-20">版权归属方式</li>
                  <li class="width-15">版权类型</li>
                  <li class="width-15">著作权人</li>
                  <li class="width-10"></li>
                </ul>
                <div v-if="list.length > 0" class="table-main">
                  <ul class="table-item width-100 flex-row justify-content-start align-items-center" v-for="(item, index) in list" :key="index">
                    <li class="width-40 flex-row justify-content-around align-items-center">
                      <el-image class="image" :src="item.targetFileUrl" fit="cover"></el-image>
                      <p class="fs-mid fw-mid black width-65 cut-text2">{{ item.title }}</p>
                    </li>
                    <li class="width-20">{{ item.ownerShipWayName }}</li>
                    <li class="width-15">{{ item.copyRightTypeName }}</li>
                    <li class="width-15">{{ item.ownerName }}</li>
                    <li class="width-10 theme-blue pointer" @click="addClick(item)">立即添加</li>
                  </ul>
                </div>
                <div v-else class="width-100 flex-column justify-content-center align-items-center">
                  <img src="../assets/image/empty.png" height="400px" />
                </div>
              </div>
            </div>
          </el-dialog>
        </div>
        <div class="width-60 form-box">
          <el-form ref="form" :model="form" :rules="rules">
            <el-form-item label="版权分类" prop="copyrightTypeOn">
              <el-select v-model="form.copyrightTypeOn" placeholder="请选择版权分类" style="width: 100%">
                <el-option v-for="(item, index) in bqsbCopyrightTypeDist" :key="index" :label="item.text" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="证书编号" prop="copyrightNo">
              <el-input v-model="form.copyrightNo" placeholder="请输入证书编号"><template slot="prepend">浙作登字 - </template></el-input>
            </el-form-item>
            <el-form-item label="版权名称" prop="title">
              <el-input v-model="form.title" placeholder="请输入版权名称"></el-input>
            </el-form-item>
            <el-form-item label="版权用途" prop="copyrightUsage">
              <el-select v-model="form.copyrightUsage" placeholder="请选择版权用途">
                <el-option v-for="(item, index) in tranCopyrightUsageDist" :key="index" :label="item.text" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="权力种类" prop="authorizationType">
              <el-radio-group v-model="form.authorizationType">
                <el-radio v-for="(item, index) in tranAuthorizationTypeDist" :key="index" :label="item.value" border>{{ item.text }}</el-radio>
                <!-- <el-radio label="按数量购买" border></el-radio>
                <el-radio label="一次性授权" border></el-radio>
                <el-radio label="面议" border></el-radio> -->
              </el-radio-group>
            </el-form-item>
            <!-- <el-form-item class="existCopyright" label="是否版权内有登记" prop="existCopyright">
              <el-radio-group v-model="form.existCopyright">
                <el-radio v-for="(item, index) in tranExistCopyrightDist" :key="index" :label="item.value" border>{{ item.text }}</el-radio>
              </el-radio-group>
            </el-form-item> -->
            <el-form-item label="是否公开" prop="publicAvailable">
              <el-radio-group v-model="form.publicAvailable">
                <el-radio v-for="(item, index) in tranPublicAvailableDist" :key="index" :label="item.value" border>{{ item.text }}</el-radio>
                <!-- <el-radio label="公开" border></el-radio> -->
                <!-- <el-radio label="未公开" border></el-radio> -->
              </el-radio-group>
            </el-form-item>
            <el-form-item label="著作权人" prop="copyrightOwnerName">
              <el-input v-model="form.copyrightOwnerName" placeholder="请输入著作权人"></el-input>
            </el-form-item>
            <el-form-item label="联系人姓名" prop="copyrightContactName">
              <el-input v-model="form.copyrightContactName" placeholder="请输入姓名"></el-input>
            </el-form-item>
            <el-form-item label="联系方式" prop="copyrightContactPhone">
              <el-input v-model="form.copyrightContactPhone" placeholder="请输入联系方式"></el-input>
            </el-form-item>
            <el-form-item label="授权时长" prop="authorizationTime">
              <el-select v-model="form.authorizationTime" placeholder="请选择授权时间">
                <el-option v-for="(item, index) in tranAuthorizationTimeDist" :key="index" :label="item.text" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="价格方式">
              <el-radio-group v-model="form.copyrightPrizeType">
                <el-radio label="1" border>输入价格</el-radio>
                <el-radio label="0" border>价格面议</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item v-if="form.copyrightPrizeType === '1'" label="版权价格">
              <el-input v-model="form.copyrightPrice" placeholder="请输入价格（元）"><template slot="append">元</template></el-input>
            </el-form-item>
            <el-form-item label="授权范围">
              <el-select v-model="form.authorizationScope" placeholder="请选择授权范围">
                <el-option v-for="(item, index) in tranAuthorizationScopeDist" :key="index" :label="item.text" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="作品说明" prop="instruction">
              <el-input
                type="textarea"
                v-model="form.instruction"
                :autosize="{ minRows: 10, maxRows: 15 }"
                placeholder="根据作品样本内容进行描述介绍，并应当说明作品的独创性内容 (比如: 文字作品要求说明文章字数以及文章内容和含义，美术作品要求"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="版权图片"
              prop="copyrightImage"
              :rules="{
                required: true,
                message: '请上传版权图片'
              }"
            >
              <!-- <el-upload
                class="avatar-uploader"
                action="https://jsonplaceholder.typicode.com/posts/"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
              >
                <img v-if="form.copyrightImage" :src="form.copyrightImage" class="avatar" />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload> -->

              <el-upload
                :limit="5"
                class="avatar-uploader"
                :before-upload="beforeImageUpload"
                :on-success="onCopyrightImageSuccess"
                :on-remove="onCopyrightImageRemove"
                :file-list="form.copyrightImages"
                :action="`${PROJECT_BASE_URL}/bqsb/util/upload/bqjy/1`"
                list-type="picture-card"
              >
                <i class="el-icon-plus" />
              </el-upload>
            </el-form-item>
            <el-form-item
              label="证书图片"
              prop="copyrightCardImage"
              :rules="{
                message: '请上传证书图片'
              }"
            >
              <!-- <el-upload
                class="avatar-uploader"
                action="https://jsonplaceholder.typicode.com/posts/"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
              >
                <img v-if="form.copyrightCardImage" :src="form.copyrightCardImage" class="avatar" />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload> -->
              <el-upload
                :limit="1"
                class="avatar-uploader"
                :before-upload="beforeImageUpload"
                :on-success="onCopyrightCardImageSuccess"
                :on-remove="onCopyrightCardImageRemove"
                :file-list="form.copyrightCardImages"
                :action="`${PROJECT_BASE_URL}/bqsb/util/upload/bqjy/2`"
                list-type="picture-card"
              >
                <i class="el-icon-plus" />
              </el-upload>
            </el-form-item>
            <el-checkbox v-model="checked" size="medium">本人承诺以上信息均真实有效</el-checkbox>
            <el-form-item>
              <el-button type="primary" @click="onSubmit">提交</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <bottom></bottom>
  </div>
</template>
<script>
import top from '../components/top';
import bottom from '../components/footer';
import { getDict } from '@/api/dict';
import { postTranCopyrightRegistration, registrationInfo, putTranCopyrightRegistration } from '@/api/tranCopyrightRegistration';
import { getMyself } from '@/api/copyright';
import * as _ from 'lodash';
// import { getTextByValue } from '@/util/helper';

// TODO 页面首次进来是，输入框显示异常

export default {
  name: 'copyrightRegistration',
  components: {
    top,
    bottom
  },
  data() {
    return {
      checked: false,
      dialogVisible: false,
      search: '',
      form: {
        copyrightTypeOn: '', //版权分类
        copyrightNo: '', //证书编号
        title: '', //版权名称
        copyrightUsage: '', //版权用途
        authorizationType: 9, //权力种类
        existCopyright: 0, //是否版权内有登记
        publicAvailable: 1, //是否公开
        copyrightOwnerName: '', // 著作权人
        copyrightContactName: null,
        copyrightContactPhone: '', //联系方式
        authorizationTime: '', //授权时间
        copyrightPrice: '', //授权价格
        authorizationScope: '', //授权范围
        instruction: '', //作品说明
        copyrightImages: [],
        copyrightImage: '', //版权图片
        copyrightCardImages: [],
        copyrightCardImage: '', //证书图片
        copyrightPrizeType: '0'
      },
      rules: {
        copyrightTypeOn: [{ required: true, message: '请选择版权分类', trigger: 'change' }],
        title: [{ required: true, message: '请输入版权名称', trigger: 'blur' }],
        copyrightUsage: [{ required: true, message: '请选择版权用途', trigger: 'change' }],
        authorizationType: [{ required: true, message: '请选择权力种类', trigger: 'change' }],
        existCopyright: [{ required: true, message: '是否版权内有登记', trigger: 'change' }],
        publicAvailable: [{ required: true, message: '是否公开', trigger: 'change' }],
        copyrightOwnerName: [{ required: true, message: '请输入著作权人', trigger: 'blur' }],
        copyrightContactName: [{ required: true, message: '请输入联系人', trigger: 'blur' }],
        copyrightContactPhone: [{ required: true, message: '请输入联系方式', trigger: 'blur' }],
        authorizationTime: [{ required: true, message: '请选择授权时间', trigger: 'change' }],
        instruction: [
          {
            required: true,
            message: '根据作品样本内容进行描述介绍，并应当说明作品的独创性内容 (比如: 文字作品要求说明文章字数以及文章内容和含义，美术作品要求',
            trigger: 'blur'
          }
        ],
        copyrightImage: [{ required: true, message: '请上传版权图片' }]
      },
      list: [],
      bqsbCopyrightTypeDist: [],
      tranCopyrightUsageDist: [],
      tranAuthorizationTypeDist: [],
      tranAuthorizationTimeDist: [],
      tranAuthorizationScopeDist: [],
      tranExistCopyrightDist: [],
      tranPublicAvailableDist: [],
      PROJECT_BASE_URL: process.env.VUE_APP_SERVE_PATH,
      params: {
        params: {
          contactMsg: 'true'
        }
      },
      myselfParams: {}
    };
  },
  async created() {
    await this.setDist();
    await this.getMyselfCopyright();
    if (this.$route.query.id) {
      this.updateForm(this.$route.query.id);
    }
  },
  methods: {
    async setDist() {
      return Promise.all([
        getDict('bqsb_copyright_type'),
        getDict('tran_copyright_usage'),
        getDict('tran_authorization_type'),
        getDict('tran_authorization_time'),
        getDict('tran_authorization_scope'),
        getDict('tran_exist_copyright'),
        getDict('tran_public_available')
      ]).then((rows) => {
        this.bqsbCopyrightTypeDist = rows[0];
        this.tranCopyrightUsageDist = rows[1];
        this.tranAuthorizationTypeDist = rows[2];
        this.tranAuthorizationTimeDist = rows[3];
        this.tranAuthorizationScopeDist = rows[4];
        this.tranExistCopyrightDist = rows[5];
        this.tranPublicAvailableDist = rows[6];
      });
    },
    async onSubmit() {
      if (!this.checked) {
        const that = this;
        this.$alert('请勾选协议！', '提示', {
          confirmButtonText: '确定',
          callback: () => {
            that.checked = true;
          }
        });
        return;
      }
      const dataForm = this.$refs.form;
      await dataForm.validate().then(
        async () => {
          if(this.form.copyrightNo) {
            if (this.form.copyrightNo.startsWith('11')) {
              this.form.copyrightNo = `浙作登字${this.form.copyrightNo}`;
            } else {
              this.form.copyrightNo = `浙作登字-${this.form.copyrightNo}`;
            }
          }

          if (this.form.id) {
            if (this.form.copyrightCardImages) {
              this.form.copyrightCardImage = this.form.copyrightCardImages
                .map((row) => {
                  if (row.response) {
                    return row.response.data.targetFileUrl;
                  } else {
                    return row.url.substring(row.url.indexOf(this.PROJECT_BASE_URL) + this.PROJECT_BASE_URL.length);
                  }
                })
                .join(',');
            }
            this.form.copyrightImage = this.form.copyrightImages
              .map((row) => {
                if (row.response) {
                  return row.response.data.targetFileUrl;
                } else {
                  return row.url.substring(row.url.indexOf(this.PROJECT_BASE_URL) + this.PROJECT_BASE_URL.length);
                }
              })
              .join(',');
            // console.log(this.form);
            await putTranCopyrightRegistration(this.form);
            this.$message.success('提交成功！');
          } else {
            this.form.copyrightImage = this.form.copyrightImages.map((row) => row.response.data.targetFileUrl).join(',');
            if (this.form.copyrightCardImages) {
              this.form.copyrightCardImage = this.form.copyrightCardImages.map((row) => row.response.data.targetFileUrl).join(',');
            }
            // console.log('form:', this.form);
            await postTranCopyrightRegistration(this.form);
            this.$message.success('提交成功！');
          }
          this.$router.go(-1);
        },
        () => {
          // TODO
        }
      );
    },
    // onSampleSuccess(res, file) {
    //   this.imageUrl = URL.createObjectURL(file.raw);
    // },
    onCopyrightImageSuccess(res, file, fileList) {
      this.form.copyrightImages = fileList;
      this.form.copyrightImage = fileList;
    },
    onCopyrightImageRemove(file, fileList) {
      this.form.copyrightImages = fileList;
      this.form.copyrightImage = fileList;
    },
    onCopyrightCardImageSuccess(res, file, fileList) {
      this.form.copyrightCardImages = fileList;
      this.form.copyrightCardImage = fileList;
    },
    onCopyrightCardImageRemove(file, fileList) {
      this.form.copyrightCardImages = fileList;
      this.form.copyrightCardImage = fileList;
    },
    beforeImageUpload(file) {
      const fileType = ['image/png', 'image/jpeg', 'image/jpg', 'image/pjpeg', 'image/x-png'];
      console.log('file.type:', file);
      if (/#/.test(file.name)) {
        this.$message.error('图片名称不可包含#');
      }
      const fileTypeCheck = !fileType.includes(file.type);
      if (!fileType.includes(file.type)) {
        this.$message.error('上传图片只能是 JPG/JPEG/PNG 格式!');
      }
      const isLt2M = file.size / 1024 / 1024 < 3;
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 3MB!');
      }
      return isLt2M && !fileTypeCheck && !/#/.test(file.name);
    },
    async getMyselfCopyright() {
      if (this.search) this.myselfParams.title = this.search;
      const myselfCopyright = await getMyself(this.myselfParams);
      this.list = myselfCopyright.data.map((row) => {
        let realUrl = null;
        if (row.copyrightFile[0].targetFileUrl.startsWith('http')) {
          realUrl = row.copyrightFile[0].targetFileUrl;
        } else {
          realUrl = this.PROJECT_BASE_URL + row.copyrightFile[0].targetFileUrl;
        }
        if (/.pdf/.test(realUrl)) realUrl = this.PROJECT_BASE_URL + `/profile/bqsb/2023/05/04/pdf.jpg`;
        if (/.dwg/.test(realUrl)) realUrl = this.PROJECT_BASE_URL + `/profile/bqsb/2024/03/25/dwg.jpg`;
        console.log('targetFileUrl:', realUrl);
        return {
          id: row.copyrightInfo.id,
          targetFileUrl: realUrl,
          copyrightImage: row.copyrightFile[0],
          title: row.copyrightInfo.title,
          ownerShipWayName: row.copyrightInfo.ownerShipWayName,
          copyrightNo: row.copyrightInfo.copyrightNo,
          copyRightTypeName: row.copyrightInfo.copyRightTypeName,
          copyrightTypeOn: row.copyrightInfo.copyRightTypeNo,
          ownerName: row.ownersInfo[0].ownerName,
          instruction: row.copyrightDetailInfo.instruction
        };
      });
    },
    async addCopyright() {
      this.dialogVisible = true;
    },
    addClick(item) {
      this.dialogVisible = false;
      this.form.title = item.title;
      this.form.copyrightOwnerName = item.ownerName;
      this.form.copyrightNo = item.copyrightNo.substring(4);
      this.form.copyrightTypeOn = item.copyrightTypeOn;
      this.form.existCopyright = 1;
      this.form.instruction = item.instruction;
    },
    async updateForm(id) {
      const info = await registrationInfo(id, this.params);
      this.form = {
        ..._.pick(info.data, [
          'id',
          'copyrightTypeOn',
          'copyrightNo',
          'title',
          'copyrightUsage',
          'authorizationType',
          'existCopyright',
          'publicAvailable',
          'copyrightOwnerName',
          'copyrightContactName',
          'copyrightContactPhone',
          'authorizationTime',
          'copyrightPrice',
          'authorizationScope',
          'instruction',
          'copyrightImage',
          'copyrightCardImage',
          'copyrightPrizeType'
        ]),
        copyrightImages: info.data.copyrightImage.split(',').map((url) => {
          return {
            url: `${this.PROJECT_BASE_URL}${url}`
          };
        }),
        copyrightCardImages: info.data.copyrightCardImage && info.data.copyrightCardImage.length > 5 ? info.data.copyrightCardImage?.split(',').map((url) => {
          return {
            url: `${this.PROJECT_BASE_URL}${url}`
          };
        }) : null,
        copyrightNo: info.data.copyrightNo ? info.data.copyrightNo.substring(4).startsWith('11') ? info.data.copyrightNo.substring(4) : info.data.copyrightNo.substring(5) : '',
        copyrightUsage: Number(info.data.copyrightUsage),
        copyrightTypeOn: Number(info.data.copyrightTypeOn),
        publicAvailable: Number(info.data.publicAvailable),
        authorizationType: Number(info.data.authorizationType),
        authorizationScope: Number(info.data.authorizationScope) ? Number(info.data.authorizationScope) : null,
        authorizationTime: Number(info.data.authorizationTime),
        copyrightPrizeType: info.data.copyrightPrizeType ? String(info.data.copyrightPrizeType) : '0'
      };
    },
    copyrightClick() {
      this.getMyselfCopyright();
    }
    // postFileJsonFun(fileJson) {
    //   await postFileJson(fileJson)
    // }
  }
};
</script>

<style lang="less" scoped>
.container {
  background: #f3f9ff;
  .container-box {
    margin: 40px 0;
    padding: 30px;
    border-radius: 10px;
    background: white;
    .icon-img {
      width: 48px;
      margin-left: 20px;
    }
    .form-box {
      padding: 60px 0;
    }
  }
  .copyrightRegistration {
    .existCopyright .el-form-item__label {
      line-height: 1.5rem;
    }
    .search-popup {
      height: 600px;
      padding: 40px;
      .search {
        border: 1px solid #dcdfe6;
        border-radius: 50px;
        margin-bottom: 40px;
        /deep/.el-input__inner {
          border: none !important;
          border-radius: 50px;
        }
      }
      .table-title {
        background: #f7f9fa;
        ul {
          margin: 20px 0;
        }
        li {
          padding: 10px 10px;
          font-size: 18px;
          text-align: center;
        }
        li:first-child {
          text-align: left;
        }
      }
      .table-main {
        height: 400px;
        overflow-y: scroll;
        .image {
          width: 25%;
          height: 60px;
        }
        ul {
          margin: 20px 0;
        }
        li {
          text-align: center;
        }
        li:first-child {
          text-align: left;
        }
      }
    }
  }
}
</style>
<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.el-form-item__label,
.el-input__inner,
.el-radio__label,
.el-textarea__inner,
.el-form-item__error {
  font-size: 18px;
}
.copyrightRegistration .el-input__inner {
  border: 1px solid #dcdfe6 !important;
  height: 3rem;
}
.copyrightRegistration .el-form-item {
  margin-bottom: 60px;
}

.copyrightRegistration .el-form-item__content {
  margin-left: 150px !important;
}

.copyrightRegistration .el-radio__input {
  opacity: 0 !important;
}
.copyrightRegistration .el-radio__input {
  opacity: 0 !important;
}
.copyrightRegistration .el-radio {
  padding-left: 0 !important;
  padding-right: 25px !important;
  margin-right: 10px !important;
}
.copyrightRegistration .el-checkbox {
  margin: 0 0 60px;
}
.copyrightRegistration .el-checkbox__label {
  font-size: 16px;
}
.copyrightRegistration .el-button--primary {
  padding: 20px 80px;
}
.copyrightRegistration .el-button--primary span {
  font-size: 20px;
}
.copyrightRegistration .el-checkbox {
  line-height: 1.5rem;
}
.copyrightRegistration .el-checkbox__inner {
  width: 1.5rem !important;
  height: 1.5rem !important;
}
.copyrightRegistration .el-checkbox__label {
  font-size: 18px !important;
}
.copyrightRegistration .el-checkbox__inner::after {
  width: 0.4rem !important;
  border-width: 0.15rem;
  top: 0.3rem !important;
}
.copyrightRegistration .el-form-item__label {
  width: 100px;
}
.copyrightRegistration .el-input__inner::placeholder {
  color: #999999 !important;
}
/deep/.copyrightRegistration .el-select {
  width: 100% !important;
}
</style>
